<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t("Fields.EditField", {}, { locale: this.$store.state.activeLang })
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="getFieldRequestFinished"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-show="!getFieldRequestFinished" />
  <div class="exclude-divs pe-2 ps-2" v-show="getFieldRequestFinished">
    <div class="row mt-3">
      <div class="col-md-12 col-sm-12">
        <div
          class="card widget widget-fields card-changed-field widget-fields-no-scale"
          :class="String.format('card-{0}', this.selectedField.name)"
        >
          <div class="card-body">
            <div
              class="widget-fields-container d-flex align-items-center justify-content-center flex-container"
            >
              <div class="widget-fields-icon">
                <div class="image-icon">
                  <i
                    class="bi"
                    :class="$root.getFieldIcon(fieldData.fieldType)"
                  ></i>
                </div>
              </div>
              <div class="widget-fields-content d-flex align-items-center br-1">
                <span class="widget-fields-text">{{
                  this.selectedField.name
                }}</span>
              </div>
              <div
                class="widget-fields-indicator"
                v-if="selectedField.description"
              >
                <template v-if="fieldData.fieldType.includes('Formula')">
                  {{
                    $t(
                      "FieldTypeDescriptions.formula_description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </template>
                <template v-else-if="fieldData.fieldType.includes('Rollup')">
                  {{
                    $t(
                      "FieldTypeDescriptions.rollup_description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </template>
                <template v-else>
                  {{
                    $t(
                      "FieldTypeDescriptions." + selectedField.description,
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <form class="form-field-edit" novalidate>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <div class="row row-cols-1">
            <div class="col col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "Fields.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="fieldData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "Fields.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control text-uppercase"
                :disabled="true"
                id="FormulaName"
                :value="fieldData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
          </div>
          <div class="row row-cols-1 mt-2">
            <div class="col col-md-12">
              <label for="Description" class="form-label">{{
                $t(
                  "Fields.Description",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                name="Description"
                class="form-control"
                id="Description"
                v-model="fieldData.description"
                :spellcheck="this.$isTextSpellCheck"
                rows="1"
              ></textarea>
            </div>
          </div>
          <div class="row row-cols-1 mt-2">
            <div
              class="col"
              :class="
                selectedFieldTypeSelectlist ||
                selectedFieldTypeOrgUnit ||
                selectedFieldTypePredefined ||
                selectedFieldTypeAutoId ||
                selectedFieldTypeFormula ||
                selectedFieldTypeRollup
                  ? 'col-md-12'
                  : 'col-md-6'
              "
            >
              <label for="HelpText" class="form-label"
                >{{
                  $t(
                    "Fields.HelpText",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="HelpText"
                v-model="fieldData.helpText"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col col-md-6" v-if="selectedFieldTypeCheckbox">
              <label for="DefaultValue" class="form-label">{{
                $t(
                  "Fields.DefaultValue",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <div class="col-12">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    :checked="Boolean.toBool(fieldData.defaultValue)"
                    :disabled="currentFieldIsSystemFormula"
                    name="checkboxDefaultValue"
                    id="checkboxDefaultValueChecked"
                    v-model="fieldData.defaultValue"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxDefaultValueChecked"
                    >{{
                      $t(
                        "Fields.Checked",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    :checked="!Boolean.toBool(fieldData.defaultValue)"
                    :disabled="currentFieldIsSystemFormula"
                    name="checkboxDefaultValue"
                    id="checkboxDefaultValueUnChecked"
                    v-model="fieldData.defaultValue"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxDefaultValueUnChecked"
                    >{{
                      $t(
                        "Fields.Unchecked",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
              </div>
            </div>
            <div class="col col-md-6" v-else-if="selectedFieldTypeSpatial">
              <label for="DefaultValue" class="form-label">{{
                $t(
                  "Fields.DefaultValue",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                @onChange="onChangeSpatial"
                :selectedData="selectedDefaultSpatial"
                :data="spatialData"
              />
            </div>
            <div
              class="col"
              :class="{
                'col-md-6': !selectedFieldTypeSelectlist,
                'col-md-12 mt-2': selectedFieldTypeSelectlist,
              }"
              v-else-if="
                !selectedFieldTypeOrgUnit &&
                !selectedFieldTypePredefined &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFormula &&
                !selectedFieldTypeRollup
              "
            >
              <label for="DefaultValue" class="form-label"
                >{{
                  $t(
                    "Fields.DefaultValue",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}<a
                  @click="openFormulaEditor"
                  class="cursor-pointer text-decoration-none text-primary ms-1"
                >
                  <span
                    ><img
                      width="20"
                      height="20"
                      :src="
                        require('@/assets/images/icons/function-primary.png')
                      " /></span
                  >&nbsp;
                  {{
                    $t(
                      "Components.FormulaEditorModal.OpenFormulaEditor",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</a
                ></label
              >
              <textarea
                name="DefaultValue"
                class="form-control"
                id="DefaultValue"
                v-model="fieldData.defaultValue"
                :spellcheck="this.$isTextSpellCheck"
                rows="1"
                :required="fieldData.setSelectedItemByFormula"
              ></textarea>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="fieldData.isActive"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="!selectedFieldTypeRollup && !selectedFieldTypeReadOnly"
            >
              <div class="form-check">
                <label
                  for="IsEditable"
                  class="form-label"
                  v-if="
                    selectedFieldTypeFormula ||
                    selectedFieldTypeFormulaNumber ||
                    selectedFieldTypeFormulaOrgUnit ||
                    selectedFieldTypeFormulaTextAreaOrSpatial
                  "
                  >{{
                    $t(
                      "Fields.FormulaIsEditableLabelText",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <label for="IsEditable" class="form-label" v-else>{{
                  $t(
                    "Fields.NotFormulaIsEditableLabelText",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsEditable"
                  v-model="fieldData.isEditable"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="isRequiredDisplay">
              <div class="form-check">
                <label for="IsRequired" class="form-label">{{
                  $t(
                    "Fields.IsRequired",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsRequired"
                  v-model="fieldData.isRequired"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-check" v-if="!selectedFieldTypeRollup">
                <label for="IsClientCalculate" class="form-label">{{
                  $t(
                    "Fields.IsClientCalculate",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsClientCalculate"
                  v-model="fieldData.isClientCalculate"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeEncrypted &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly &&
                !selectedFieldTypeTextArea
              "
            >
              <div class="form-check">
                <label for="IsUnique" class="form-label">{{
                  $t(
                    "Fields.IsUnique",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsUnique"
                  v-model="fieldData.isUnique"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly &&
                !selectedFieldTypeTextArea
              "
            >
              <div class="form-check">
                <label for="IsCheckUnchanged" class="form-label">{{
                  $t(
                    "Fields.IsCheckUnchanged",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCheckUnchanged"
                  v-model="fieldData.isCheckUnchanged"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeCheckbox &&
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeAutoId &&
                !selectedFieldTypeFile &&
                !selectedFieldTypeImage &&
                !selectedFieldTypeHtml &&
                !selectedFieldTypeFormulaTextAreaOrSpatial &&
                !selectedFieldTypeReadOnly
              "
            >
              <div class="form-check">
                <label for="IsUniqueCheckClientCalculate" class="form-label">{{
                  $t(
                    "Fields.IsUniqueCheckClientCalculate",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsUniqueCheckClientCalculate"
                  v-model="fieldData.isUniqueCheckClientCalculate"
                />
              </div>
            </div>
            <div
              class="col-md-6"
              v-if="
                !selectedFieldTypeSpatial &&
                !selectedFieldTypeRollup &&
                !selectedFieldTypeReadOnly
              "
            >
              <div class="form-check">
                <label for="IsCalculateOnClientChange" class="form-label">{{
                  $t(
                    "Fields.IsCalculateOnClientChange",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCalculateOnClientChange"
                  v-model="fieldData.isCalculateOnClientChange"
                  @change="onChangeCalculateOnClientChange"
                />
              </div>
            </div>
            <div
              class="col-md-12 mb-3"
              v-if="fieldData.isCalculateOnClientChange"
            >
              <label for="CalculateTriggerField" class="form-label required">{{
                $t(
                  "Fields.CalculateTriggerField",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :selectedData="selectedCalculateTriggerFields"
                @onMultipleSelectChange="
                  onChangeMultipleSelectForCalculateTriggerField
                "
                :isDisabled="
                  !fieldData.isCalculateOnClientChange ? true : false
                "
                :allowEmpty="false"
                :isParameters="true"
                :isMultiple="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryAllFormulizedFieldsWithFormula?parentFormulaName={0}',
                    this.fieldData.customObjectFormulaName
                  )
                "
                :excludeId="$route.params.fieldId"
              />
            </div>
            <div
              class="col-md-6"
              v-if="!selectedFieldTypeAutoId && !selectedFieldTypeRollup"
            >
              <div class="form-check">
                <label for="IsCalculatedOnlyOnInsert" class="form-label">{{
                  $t(
                    "Fields.IsCalculatedOnlyOnInsert",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  v-if="currentFieldIsSystemFormula"
                  class="form-check-input"
                  type="checkbox"
                  id="IsCalculatedOnlyOnInsert"
                  disabled
                  :checked="fieldData.isCalculatedOnlyOnInsert"
                />
                <input
                  v-else
                  class="form-check-input"
                  type="checkbox"
                  @change="onChangeCalculatedOnlyOnInsert"
                  id="IsCalculatedOnlyOnInsert"
                  v-model="fieldData.isCalculatedOnlyOnInsert"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="selectedFieldTypeLookup">
              <div class="form-check">
                <label for="IsDataReadFromMainTable" class="form-label">{{
                  $t(
                    "Fields.IsDataReadFromMainTable",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsDataReadFromMainTable"
                  v-model="fieldData.isDataReadFromMainTable"
                />
              </div>
            </div>
          </div>

          <div v-show="selectedFieldTypeLookup" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="LookupObject" class="form-label required">{{
                    $t(
                      "Fields.LookupObject",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeLookupObject"
                    :selectedData="selectedLookup"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    routeLink="#/CustomObject/Detail/"
                    :allowEmpty="false"
                    :isGatewayRequest="true"
                    requestUrl="/Lcdp-SummaryCustomObjectsWithFormula"
                  />
                </div>
                <div class="col-md-6">
                  <label for="ViewFilter" class="form-label required">{{
                    $t(
                      "Fields.ViewFilter",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="multipleSelectValueReset"
                    @onChange="onChangeLookupObjectViewFilter"
                    :selectedData="selectedViewFilter"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label
                    for="IsMultiple"
                    class="form-label"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="
                      this.$t(
                        'BaseModelFields.NoUpdatedFeature',
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    "
                    >{{
                      $t(
                        "Fields.MultipleSelect",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <input
                    class="form-check-input"
                    disabled
                    type="checkbox"
                    id="IsMultiple"
                    :checked="fieldData.isMultipleValue"
                  />
                </div>
                <div class="form-check">
                  <label for="IsPlusButtonActive" class="form-label">{{
                    $t(
                      "Fields.IsPlusButtonActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsPlusButtonActive"
                    v-model="fieldData.isPlusButtonActive"
                  />
                </div>
                <div class="form-check">
                  <label for="IsViewingFilterButtonActive" class="form-label">{{
                    $t(
                      "Fields.IsViewingFilterButtonActive",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsViewingFilterButtonActive"
                    v-model="fieldData.isViewingFilterButtonActive"
                  />
                </div>
                <div class="form-check">
                  <label for="IsSplitSearch" class="form-label">{{
                    $t(
                      "Fields.IsSplitSearch",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsSplitSearch"
                    v-model="fieldData.isSplitSearch"
                  />
                </div>
                <div class="form-check">
                  <label for="IsParentPredefined" class="form-label">{{
                    $t(
                      "Fields.IsParentPredefined",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsParentPredefined"
                    :disabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                    v-model="fieldData.isParentPredefined"
                    @change="onChangeIsParentPredefined"
                  />
                </div>
                <div
                  class="row mb-3"
                  v-show="
                    fieldData.isParentPredefined &&
                    !String.isNullOrWhiteSpace(
                      this.fieldData.lookupObjectFormulaName
                    )
                  "
                >
                  <div class="col">
                    <label for="ControlingField" class="form-label required">{{
                      $t(
                        "Fields.ControlingField",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeForControllingFieldPublicId"
                      :selectedData="selectedControllingField"
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      openDirection="top"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          this.$route.params.customObjectId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-LookupControllingItemsWithFormula?formulaName={0}&childCustomObjectFormulaName={1}&customObjectFormulaName={2}',
                          this.fieldData.formulaName,
                          this.fieldData.lookupObjectFormulaName,
                          this.fieldData.customObjectFormulaName
                        )
                      "
                    />
                  </div>
                  <div class="col">
                    <label
                      for="ControlingRelationField"
                      class="form-label required"
                      >{{
                        $t(
                          "Fields.ControlingRelationField",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeForControllingRelationFieldPublicId"
                      :selectedData="selectedControllingRelationField"
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDisabled="
                        String.isNullOrWhiteSpace(
                          this.fieldData.controllingCustomObjectFormulaName
                        )
                      "
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      openDirection="top"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          this.fieldData.lookupObjectPublicId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
                          this.fieldData.controllingCustomObjectFormulaName,
                          this.fieldData.lookupObjectFormulaName
                        )
                      "
                    />
                  </div>
                </div>
                <div class="form-check">
                  <label for="IsLookupFieldGroupWanted" class="form-label">{{
                    $t(
                      "Fields.IsLookupFieldGroupWanted",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsLookupFieldGroupWanted"
                    :disabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.viewFilterFormulaName
                      )
                    "
                    v-model="fieldData.isLookupFieldGroupWanted"
                  />
                </div>
                <div
                  class="col-12 mt-1"
                  v-if="
                    fieldData.isLookupFieldGroupWanted &&
                    !String.isNullOrWhiteSpace(
                      this.fieldData.viewFilterFormulaName
                    )
                  "
                >
                  <label
                    for="LookupFieldPublicIds"
                    class="form-label required"
                    >{{
                      $t(
                        "Fields.LookupFieldPublicIds",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onMultipleSelectChange="
                      onChangeMultipleSelectForLookupObjectFields
                    "
                    :selectedData="selectedLookupObjectFields"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isMultiple="true"
                    :isGatewayRequest="true"
                    openDirection="top"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFiltersFieldsWithFormula?coFormulaName={0}&viewFilterFormulaName={1}',
                        this.fieldData.lookupObjectFormulaName,
                        this.fieldData.viewFilterFormulaName
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeNumber" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="FieldType" class="form-label required">{{
                    $t(
                      "Fields.FieldType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :isDisabled="true"
                    :selectedData="selectedNumberFieldType"
                    :data="numberFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeNumberFieldType"
                  />
                </div>
                <div
                  class="col-md-6"
                  v-if="!String.isNullOrWhiteSpace(this.fieldData.fieldTypeId)"
                >
                  <label for="Description" class="form-label">{{
                    $t(
                      "Fields.Description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <div class="alert alert-info mb-0 p-2">
                    <span v-if="this.fieldData.fieldTypeId == 3">{{
                      $t(
                        "FieldTypeDescriptions.decimal_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 33">{{
                      $t(
                        "FieldTypeDescriptions.long_integer_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 34">{{
                      $t(
                        "FieldTypeDescriptions.double_precision_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                    <span v-if="this.fieldData.fieldTypeId == 8">{{
                      $t(
                        "FieldTypeDescriptions.percent_field_type_description",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  this.fieldData.fieldTypeId != 8 &&
                  !String.isNullOrWhiteSpace(this.fieldData.fieldTypeId)
                "
              >
                <div
                  class="col-12 mt-3"
                  v-if="this.fieldData.fieldTypeId == 33"
                >
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                    />
                  </div>
                </div>
                <div class="col-12 mt-3" v-else>
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label for="Length" class="form-label"
                      >{{
                        $t(
                          "Fields.Length",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <h6 class="badge badge-warning text-dark">
                      {{ fieldData.length }}
                    </h6>
                    <input
                      type="range"
                      class="form-range field-length"
                      min="1"
                      max="18"
                      v-model="fieldData.length"
                      @oninput="this.fieldData.length = this.value"
                    />
                  </div>
                  <template v-if="this.fieldData.fieldTypeId != 33">
                    <div
                      class="col-md-6"
                      v-if="this.fieldData.fieldTypeId == 34"
                    >
                      <label for="DecimalPlaces" class="form-label">{{
                        $t(
                          "Fields.DecimalPlaces",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <h6 class="badge badge-warning text-dark">
                        {{ fieldData.decimalPlaces }}
                      </h6>
                      <input
                        type="range"
                        class="form-range field-decimal-places"
                        min="0"
                        max="18"
                        v-model="fieldData.decimalPlaces"
                        @oninput="this.fieldData.decimalPlaces = this.value"
                      />
                    </div>
                    <div class="col-md-6" v-else>
                      <label for="DecimalPlaces" class="form-label">{{
                        $t(
                          "Fields.DecimalPlaces",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <h6 class="badge badge-warning text-dark">
                        {{ fieldData.decimalPlaces }}
                      </h6>
                      <input
                        type="range"
                        class="form-range field-decimal-places"
                        min="0"
                        max="6"
                        v-model="fieldData.decimalPlaces"
                        @oninput="this.fieldData.decimalPlaces = this.value"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeText" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  ({{
                    $t(
                      "Fields.Max450Character",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }})</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="450"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeTextArea" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  {{
                    $t(
                      "Fields.ZeroIsUnlimited",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="999999"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeFile" class="col-12">
            <div class="form-check">
              <label
                for="IsMultiple"
                class="form-label"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="
                  this.$t(
                    'BaseModelFields.NoUpdatedFeature',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
                >{{
                  $t(
                    "Fields.MultipleSelect",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="IsMultiple"
                disabled
                :checked="fieldData.isMultipleValue"
              />
            </div>
          </div>

          <div v-if="selectedFieldTypeImage" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="ImageSelection" class="form-label required"
                  >{{
                    $t(
                      "Fields.ImageSelection",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedDisplayOptions"
                  :data="imageSelectionData"
                  :allowEmpty="false"
                  @onChange="onChangeForImageSelection"
                />
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label
                    for="IsMultiple"
                    class="form-label"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="
                      this.$t(
                        'BaseModelFields.NoUpdatedFeature',
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    "
                    >{{
                      $t(
                        "Fields.MultipleSelect",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsMultiple"
                    disabled
                    :checked="fieldData.isMultipleValue"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeHtml" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <label for="Property" class="form-label">{{
                  $t(
                    "Fields.Type",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
                ><br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    name="checkboxProperyText"
                    id="checkboxProperyText"
                    v-model="fieldData.property"
                  />
                  <label class="form-check-label" for="checkboxProperyText">{{
                    $t(
                      "Fields.Article",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    name="checkboxProperyDocument"
                    id="checkboxProperyDocument"
                    v-model="fieldData.property"
                  />
                  <label
                    class="form-check-label"
                    for="checkboxProperyDocument"
                    >{{
                      $t(
                        "Fields.Document",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
              </div>
              <div class="col-12 mt-3">
                <label for="Length" class="form-label required"
                  >{{
                    $t(
                      "Fields.Length",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  {{
                    $t(
                      "Fields.ZeroIsUnlimited",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="Length"
                  v-model="fieldData.length"
                  min="0"
                  max="999999"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeSelectlist" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="col-12 mt-3">
                <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-active-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-actives"
                      type="button"
                      role="tab"
                      :aria-selected="true"
                    >
                      {{
                        $t(
                          "Fields.Active",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                      <span
                        class="badge badge-success bg-success rounded-pill"
                        >{{ fieldData.activeFieldItems.length }}</span
                      >
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-passive-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-passives"
                      type="button"
                      role="tab"
                      :aria-selected="false"
                    >
                      {{
                        $t(
                          "Fields.Passive",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                      <span class="badge badge-danger bg-danger rounded-pill">{{
                        fieldData.passiveFieldItems.length
                      }}</span>
                    </button>
                  </li>
                </ul>
                <div class="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="pills-actives"
                    role="presentation"
                  >
                    <draggable
                      :list="fieldData.activeFieldItems"
                      item-key="key"
                      handle=".handle"
                      ghost-class="ghost"
                      v-bind="{
                        group: 'activeFieldItems',
                        sort: true,
                        disabled: false,
                        animation: 300,
                      }"
                    >
                      <template #item="{ element }">
                        <div class="card mb-1">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-1 handle">
                                <i
                                  class="bi bi-grip-horizontal row-draggable"
                                ></i>
                              </div>
                              <div
                                style="font-size: 13px; vertical-align: middle"
                                class="col-1"
                              >
                                {{
                                  fieldData.activeFieldItems.findIndex(
                                    (f) => f.key == element.key
                                  ) + 1
                                }}
                              </div>
                              <div class="col-4">
                                <span
                                  style="font-size: 12px"
                                  class="badge badge-success bg-success"
                                  >{{ element.value }}
                                </span>
                              </div>
                              <div class="col-4">
                                <span
                                  style="font-size: 12px"
                                  class="badge text-dark ms-2"
                                  >{{ element.key }}</span
                                >
                              </div>
                              <div class="col-2">
                                <div
                                  class="form-check form-switch form-switch-edit-field"
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    @change="
                                      addSelectlistItem(
                                        element.key,
                                        element.value,
                                        1
                                      )
                                    "
                                    checked
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-passives"
                    role="presentation"
                  >
                    <div
                      class="card mb-1"
                      v-for="(element, index) in fieldData.passiveFieldItems"
                      :key="element.key"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div
                            style="font-size: 13px; vertical-align: middle"
                            class="col-1"
                          >
                            {{ index + 1 }}
                          </div>
                          <div class="col-4">
                            <span
                              style="font-size: 12px"
                              class="badge badge-danger bg-danger"
                              >{{ element.value }}
                            </span>
                          </div>
                          <div class="col-4">
                            <span
                              style="font-size: 12px"
                              class="badge text-dark ms-2"
                              >{{ element.key }}</span
                            >
                          </div>
                          <div class="col-3">
                            <div
                              class="form-check form-switch form-switch-edit-field"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                @change="
                                  addSelectlistItem(
                                    element.key,
                                    element.value,
                                    2
                                  )
                                "
                                checked
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  for="SelectListItems"
                  class="form-label"
                  :class="{ required: fieldData.activeFieldItems.length == 0 }"
                  >{{
                    $t(
                      "Fields.SelectListItems",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                >
                <template v-if="fieldData.activeFieldItems.length == 0">
                  <textarea
                    name="SelectListItems"
                    v-on:keyup="selectlistItemsOnKeyup"
                    style="height: auto"
                    class="form-control"
                    id="SelectListItems"
                    v-model="fieldData.selectListItems"
                    :spellcheck="this.$isTextSpellCheck"
                    ref="selectListItems"
                    rows="2"
                    required
                  ></textarea>
                </template>
                <textarea
                  v-else
                  name="SelectListItems"
                  v-on:keyup="selectlistItemsOnKeyup"
                  style="height: auto"
                  class="form-control"
                  id="SelectListItems"
                  v-model="fieldData.selectListItems"
                  :spellcheck="this.$isTextSpellCheck"
                  ref="selectListItems"
                  rows="2"
                ></textarea>
              </div>

              <div
                v-if="!fieldData.setSelectedItemByFormula"
                class="col-12 mt-3"
              >
                <label for="SelectListSelectedItems" class="form-label">{{
                  $t(
                    "Fields.SelectListSelectedItems",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :data="selectlistItems"
                  :selectedData="selectListDefaultValue"
                  :isMultiple="true"
                  @onMultipleSelectChange="
                    onChangeMultipleSelectForSelectlistItems
                  "
                  @onMultipleSelectChangeReturnValues="
                    onChangeMultipleSelectForSelectlistItemValues
                  "
                  v-if="this.fieldData.isMultipleValue"
                />
                <FormSelect
                  type="SelectWithLocalData"
                  :data="selectlistItems"
                  :selectedData="selectListDefaultValue"
                  @onChange="onChangeSelectListItems"
                  v-else
                />
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsShortAlphabetically" class="form-label">{{
                      $t(
                        "Fields.IsShortAlphabetically",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsShortAlphabetically"
                      v-model="fieldData.isShortAlphabetically"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label
                      for="IsMultiple"
                      class="form-label"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        this.$t(
                          'BaseModelFields.NoUpdatedFeature',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      >{{
                        $t(
                          "Fields.MultipleSelect",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <input
                      class="form-check-input"
                      disabled
                      type="checkbox"
                      id="IsMultiple"
                      :checked="fieldData.isMultipleValue"
                    />
                  </div>
                </div>
                <!-- <div class="col-md-6">
                                    <div class="form-check">
                                        <label for="IsControllingFilterAnd" class="form-label">{{$t('Fields.IsControllingFilterAnd', {}, {locale:this.$store.state.activeLang})}}</label>
                                        <input class="form-check-input" type="checkbox" id="IsControllingFilterAnd" v-model="fieldData.isControllingFilterAnd">
                                    </div>
                                </div> -->
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsRadio" class="form-label">{{
                      $t(
                        "Fields.IsRadio",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsRadio"
                      v-model="fieldData.isRadio"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="SetSelectedItemByFormula" class="form-label">{{
                      $t(
                        "Fields.SetSelectedItemByFormula",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="SetSelectedItemByFormula"
                      v-model="fieldData.setSelectedItemByFormula"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypePredefined" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="Predefinedlists" class="form-label required">{{
                    $t(
                      "Fields.Predefinedlists",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="this.selectedPredefinedList"
                    @onChange="onChangePredefinedList"
                    :allowEmpty="false"
                    :data="predefinedTypeData"
                  />
                </div>
                <div class="col-md-6" v-if="!fieldData.isParentPredefined">
                  <label for="DefaultValue" class="form-label">{{
                    $t(
                      "Fields.DefaultValue",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>

                  <FormSelect
                    v-if="this.fieldData.isMultiple"
                    type="SelectWithRemoteDataAndInternalSearch"
                    :selectedData="selectedPredefined"
                    @onMultipleSelectChangeReturnValues="
                      onChangePredefinedMultipleSelectForDefaultValue
                    "
                    :isParameters="true"
                    :isMultiple="true"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryPredefinedItems?parentId={0}',
                        this.fieldData.predefinedPublicId
                      )
                    "
                    :resetSelect="defaultValueReset"
                  />

                  <FormSelect
                    v-else
                    type="SelectWithRemoteDataAndInternalSearch"
                    :selectedData="selectedPredefined"
                    @onChange="onChangePredefinedForDefaultValue"
                    :resetSelect="defaultValueReset"
                    :isParameters="true"
                    :isMultiple="false"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryPredefinedItems?parentId={0}',
                        this.fieldData.predefinedPublicId
                      )
                    "
                  />
                </div>
                <div class="col-md-6" v-else>
                  <label for="ControlingField" class="form-label required">{{
                    $t(
                      "Fields.ControlingField",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :selectedData="selectedControllingField"
                    @onChange="onChangePrefinedControlling"
                    :resetSelect="defaultValueReset"
                    :isParameters="true"
                    :isMultiple="false"
                    :allowEmpty="false"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-PredefinedControllingItems?childId={0}&customObjectPublicId={1}',
                        this.fieldData.predefinedPublicId,
                        this.$route.params.customObjectId
                      )
                    "
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label
                      for="IsMultiple"
                      class="form-label"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :title="
                        this.$t(
                          'BaseModelFields.NoUpdatedFeature',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      >{{
                        $t(
                          "Fields.MultipleSelect",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <input
                      class="form-check-input"
                      disabled
                      type="checkbox"
                      id="IsMultiple"
                      :checked="fieldData.isMultiple"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsParentPredefined" class="form-label">{{
                      $t(
                        "Fields.IsParentPredefined",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsParentPredefined"
                      v-model="fieldData.isParentPredefined"
                      @change="onChangeIsParentPredefined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeEncrypted" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="EncryptedType" class="form-label required">{{
                    $t(
                      "Fields.EncryptedType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="encryptedTypeData[0]"
                    @onChange="onChangeEncryptedTypeData"
                    :allowEmpty="false"
                    :data="encryptedTypeData"
                  />
                </div>
                <div class="col-md-6">
                  <label for="Regex" class="form-label required">{{
                    $t(
                      "Fields.Regex",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Regex"
                    v-model="fieldData.regex"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    required
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="ReadMask" class="form-label required">{{
                    $t(
                      "Fields.ReadMask",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ReadMask"
                    v-model="fieldData.readMask"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    required
                  />
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <label for="IsEncrypted" class="form-label">{{
                    $t(
                      "Fields.IsEncrypted",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IsEncrypted"
                    v-model="fieldData.isEncrypted"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeRollup" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="FieldType" class="form-label required">{{
                    $t(
                      "Fields.FieldType",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :selectedData="selectedRollup"
                    :data="rollupFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeRollupFieldType"
                  />
                </div>
                <div class="col-md-6">
                  <label for="AggregateFunction" class="form-label required">{{
                    $t(
                      "Fields.AggregateFunction",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :resetSelect="aggregateFunctionValueReset"
                    :selectedData="selectedAggFunction"
                    :data="aggregateFunctions"
                    :allowEmpty="false"
                    @onChange="onChangeAggregateFunctions"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="LookupObject" class="form-label required">{{
                    $t(
                      "Fields.LookupObject",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeLookupObject"
                    :selectedData="selectedLookupObject"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    routeLink="#/CustomObject/Detail/"
                    :allowEmpty="false"
                    :isGatewayRequest="true"
                    requestUrl="/Lcdp-SummaryCustomObjectsWithFormula"
                  />
                </div>
                <div class="col-md-6">
                  <label for="ViewFilter" class="form-label required">{{
                    $t(
                      "Fields.ViewFilter",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="multipleSelectValueReset"
                    @onChange="onChangeLookupObjectViewFilter"
                    :selectedData="selectedViewFilter"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.lookupObjectFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummarySystemViewFiltersWithFormula?coFormulaName={0}',
                        this.fieldData.lookupObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="Fields" class="form-label required">{{
                    $t(
                      "Fields.RollupFields",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :resetSelect="rollupFieldValueReset"
                    @onChange="onChangeRollupField"
                    :selectedData="selectedRollupField"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditField/{0}&fieldId=',
                        this.fieldData.lookupObjectPublicId
                      )
                    "
                    :isDisabled="
                      String.isNullOrWhiteSpace(
                        this.fieldData.viewFilterFormulaName
                      )
                        ? true
                        : false
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryAggregateFieldsWithFormula?parentFormulaName={0}&type={1}&aggregateFunctionId={2}',
                        this.fieldData.lookupObjectFormulaName,
                        this.fieldData.fieldTypeId,
                        this.fieldData.aggregateFunctionId
                      )
                    "
                  />
                </div>
                <div class="col-md-6">
                  <div class="col">
                    <label
                      for="ControlingRelationField"
                      class="form-label required"
                      >{{
                        $t(
                          "Fields.ControlingRelationField",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      :resetSelect="rollupFieldValueReset"
                      :selectedData="selectedControllingRelationField"
                      @onChange="
                        onChangeForControllingRelationFieldPublicIdRollup
                      "
                      :allowEmpty="false"
                      :isParameters="true"
                      :isDisabled="
                        String.isNullOrWhiteSpace(
                          fieldData.lookupObjectFormulaName
                        )
                      "
                      :isDetailIconShow="true"
                      selectedPrimaryKey="publicId"
                      :routeLink="
                        String.format(
                          '#/CustomObject/EditField/{0}&fieldId=',
                          this.fieldData.lookupObjectPublicId
                        )
                      "
                      :isGatewayRequest="true"
                      :requestUrl="
                        String.format(
                          '/Lcdp-SummaryLookupFieldsWithFormula?type=Lookup&parentFormulaName={0}&query={1}',
                          this.fieldData.customObjectFormulaName,
                          this.fieldData.lookupObjectFormulaName
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="row mt-3"
                v-if="!selectedFieldTypeRollupDateOrDateTime"
              >
                <div class="col-md-6">
                  <label for="Length" class="form-label"
                    >{{
                      $t(
                        "Fields.Length",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.length }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="1"
                    max="18"
                    v-model="fieldData.length"
                    @oninput="this.fieldData.length = this.value"
                  />
                </div>
                <div class="col-md-6">
                  <label for="DecimalPlaces" class="form-label">{{
                    $t(
                      "Fields.DecimalPlaces",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.decimalPlaces }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="0"
                    max="6"
                    v-model="fieldData.decimalPlaces"
                    @oninput="this.fieldData.decimalPlaces = this.value"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div
                  class="col-md-6"
                  v-if="!selectedFieldTypeRollupDateOrDateTime"
                >
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <label for="IsUpdateWholeRecord" class="form-label">{{
                      $t(
                        "Fields.IsUpdateWholeRecord",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsUpdateWholeRecord"
                      v-model="fieldData.isUpdateWholeRecord"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeFormula" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label
                    for="FieldType"
                    class="form-label required"
                    :disabled="true"
                    >{{
                      $t(
                        "Fields.FieldType",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <FormSelect
                    type="SelectWithLocalData"
                    :isDisabled="true"
                    :selectedData="selectedFormulaFieldType"
                    :data="formulaFieldTypes"
                    :allowEmpty="false"
                    @onChange="onChangeFormulaFieldType"
                  />
                </div>
                <div class="col-md-6" v-if="fieldData.isTableRollup">
                  <label for="AggregateFunction" class="form-label">{{
                    $t(
                      "Fields.AggregateFunction",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithLocalData"
                    :isDisabled="currentFieldIsSystemFormula"
                    :data="aggregateFunctions"
                    @onChange="onChangeAggregateFunctions"
                  />
                </div>
              </div>
              <div class="row mt-3" v-if="fieldData.isTableRollup">
                <div class="col-md-6">
                  <label for="TablePublicId" class="form-label">{{
                    $t(
                      "Fields.Table",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    @onChange="onChangeTablePublicId"
                    :isParameters="true"
                    :isDisabled="currentFieldIsSystemFormula"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFieldsByTypeWithFormula?parentFormulaName={0}&type=Table',
                        this.fieldData.customObjectFormulaName
                      )
                    "
                  />
                </div>
                <div class="col-md-6">
                  <label for="TableFields" class="form-label">{{
                    $t(
                      "Fields.TableFields",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :isDisabled="
                      String.isNullOrWhiteSpace(fieldData.tablePublicId)
                        ? true
                        : currentFieldIsSystemFormula
                        ? true
                        : false
                    "
                    @onChange="onChangeRollupFieldPublicId"
                    :isParameters="true"
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryTableFieldsWithFormula?parentFormulaName={0}&tableId={1}&type=Table',
                        this.fieldData.customObjectFormulaName,
                        this.fieldData.tablePublicId
                      )
                    "
                  />
                </div>
              </div>

              <OrganizationalUnitTab
                v-if="selectedFieldTypeFormulaOrgUnit"
                ref="formulaOrganizationalUnitTab"
                :data="fieldData"
              />

              <div
                class="row"
                :class="{
                  'mt-3': !selectedFieldTypeFormulaOrgUnit,
                }"
              >
                <div class="col-md-12">
                  <label for="Formula" class="form-label"
                    >{{
                      $t(
                        "Fields.Formula",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}<a
                      v-if="!currentFieldIsSystemFormula"
                      @click="openFormulaEditor"
                      class="cursor-pointer text-decoration-none text-primary ms-1"
                    >
                      <span
                        ><img
                          width="20"
                          height="20"
                          :src="
                            require('@/assets/images/icons/function-primary.png')
                          " /></span
                      >&nbsp;
                      {{
                        $t(
                          "Components.FormulaEditorModal.OpenFormulaEditor",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</a
                    ></label
                  >
                  <textarea
                    name="Formula"
                    class="form-control"
                    id="Formula"
                    :disabled="
                      currentFieldIsSystemFormula &&
                      fieldData.formulaName != 'SISTEM_NUMARASI'
                    "
                    v-model="fieldData.defaultValue"
                    :spellcheck="this.$isTextSpellCheck"
                    rows="2"
                  ></textarea>
                </div>
              </div>

              <div class="row mt-3" v-if="selectedFieldTypeFormulaNumber">
                <div class="col-md-6">
                  <label for="Length" class="form-label"
                    >{{
                      $t(
                        "Fields.Length",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.length }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="1"
                    max="18"
                    v-model="fieldData.length"
                    @oninput="this.fieldData.length = this.value"
                  />
                </div>
                <div class="col-md-6">
                  <label for="DecimalPlaces" class="form-label">{{
                    $t(
                      "Fields.DecimalPlaces",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label>
                  <h6 class="badge badge-warning text-dark">
                    {{ fieldData.decimalPlaces }}
                  </h6>
                  <input
                    type="range"
                    class="form-range"
                    min="0"
                    max="6"
                    v-model="fieldData.decimalPlaces"
                    @oninput="this.fieldData.decimalPlaces = this.value"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <!-- <div class="col-md-6" v-if="selectedFieldTypeFormulaNumber">
                  <div class="form-check">
                    <label for="IsTableRollup" class="form-label">{{
                      $t(
                        "Fields.IsTableRollup",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsTableRollup"
                      v-model="fieldData.isTableRollup"
                      @change="onChangetableRollup"
                    />
                  </div>
                </div> -->
                <div class="col-md-6" v-if="selectedFieldTypeFormulaNumber">
                  <div class="form-check">
                    <label for="IsThousandSeparator" class="form-label">{{
                      $t(
                        "Fields.IsThousandSeparator",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsThousandSeparator"
                      v-model="fieldData.isThousandSeparator"
                      @change="
                        fieldData.isThousandSeparator
                          ? (this.fieldData.decimalPlaces = 2)
                          : (this.fieldData.decimalPlaces = 0)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedFieldTypeAutoId" class="card mt-2">
            <div class="card-body">
              <h3 class="card-title">
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "Fields.CustomSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="form-check">
                    <label
                      for="IsAutoIdTextBasedIncrement"
                      class="form-label"
                      >{{
                        $t(
                          "Fields.IsAutoIdTextBasedIncrement",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="IsAutoIdTextBasedIncrement"
                      :disabled="currentFieldIsSystemFormula"
                      v-model="fieldData.isAutoIdTextBasedIncrement"
                      @change="onChangeIsAutoIdTextBasedIncrement"
                    />
                  </div>
                </div>
                <div
                  class="col-md-6"
                  v-if="fieldData.isAutoIdTextBasedIncrement"
                >
                  <label
                    for="AutoIdIncrementField"
                    class="form-label required"
                    >{{
                      $t(
                        "Fields.AutoIdIncrementField",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                  <FormSelect
                    type="SelectWithRemoteDataAndInternalSearch"
                    :selectedData="selectedAutoIncrement"
                    @onChange="onChangeAutoIdIncrementField"
                    :allowEmpty="false"
                    :isParameters="true"
                    :isDisabled="currentFieldIsSystemFormula"
                    :isDetailIconShow="true"
                    selectedPrimaryKey="publicId"
                    :routeLink="
                      String.format(
                        '#/CustomObject/EditField/{0}&fieldId=',
                        this.$route.params.customObjectId
                      )
                    "
                    :isGatewayRequest="true"
                    :requestUrl="
                      String.format(
                        '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
                        this.fieldData.customObjectFormulaName
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <OrganizationalUnitTab
            v-if="selectedFieldTypeOrgUnit"
            ref="organizationalUnitTab"
            :data="fieldData"
          />

          <ActionButtons
            v-if="$isMobile()"
            @methodSave="onSubmit('btn-save')"
            :isNewRecord="false"
          />
        </form>
      </div>
    </div>
  </div>
  <AlertModal
    messageKey="Fields.IsCalculatedOnlyOnInsertUnCheckedMessage"
    @yes="calculatedOnlyOnInsertYes"
    @no="calculatedOnlyOnInsertNo"
  />
  <FormulaEditorModal
    :formulaField="formulaField"
    parentRecordPrefix=""
    @updateFormula="updateFormula"
  />
</template>
<script>
import draggable from "vuedraggable";
import AlertModal from "@//components/custom/alert/AlertModal.vue";
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
import OrganizationalUnitTab from "@/components/custom/custom-settings/OrganizationalUnitTab.vue";
import $ from "jquery";
export default {
  name: "CustomObjectEditField",
  components: {
    AlertModal,
    FormulaEditorModal,
    OrganizationalUnitTab,
    draggable,
  },
  data() {
    return {
      getFieldRequestFinished: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      fieldData: {},
      imageSelectionData: [
        {
          key: "only_view_on_detail",
          value: this.$t(
            "Fields.OnylViewOnDetail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "only_view_on_modal",
          value: this.$t(
            "Fields.OnlyViewOnModal",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "only_view_on_download",
          value: this.$t(
            "Fields.OnlyViewOnDownload",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      predefinedTypeData: [
        {
          key: "4A01FDD1F1894532A3CC15DBF04D68FA",
          value: this.$t(
            "Fields.TurkeyCities",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4FA4DE40FC4D4899BF540905800282EB",
          value: this.$t(
            "Fields.TurkeyCounties",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "09E8E285197849829B55333C924E024A",
          value: this.$t(
            "Fields.TurkeyTowns",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "38E1F4B4B774411593056EACE62C2A10",
          value: this.$t(
            "Fields.TurkeyDistricts",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "C3CECD15054F48D4B01882FAFA217B28",
          value: this.$t(
            "Fields.TurkeyPostalCodes",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "65274AE7E3AB455B977C4306177B656C",
          value: this.$t(
            "Fields.Currencies",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "D341199798EB4BC29358CD7512241429",
          value: this.$t(
            "Fields.Languages",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "289B0F8684F0433D9FCE7A37DD64A363",
          value: this.$t(
            "Fields.CountriesInTurkish",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "49ED54DF0A47415BB4726316E6762872",
          value: this.$t(
            "Fields.CountriesInEnglish",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      encryptedTypeData: [
        {
          key: "1",
          value: this.$t(
            "Fields.EncryptedCreditCard",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "Fields.EncryptedEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "Fields.EncryptedPassword",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      rollupFieldTypes: [
        { key: "23", value: "Rollup" },
        { key: "28", value: "RollupDate" },
        { key: "29", value: "RollupDateTime" },
      ],
      formulaFieldTypes: [
        { key: "18", value: "FormulaString" },
        { key: "24", value: "FormulaNumber" },
        { key: "25", value: "FormulaDateTime" },
        { key: "26", value: "FormulaDate" },
        { key: "30", value: "FormulaTextArea" },
        { key: "31", value: "FormulaSpatial" },
        { key: "32", value: "FormulaOrganizationalUnit" },
      ],
      numberFieldTypes: [
        { key: "3", value: "Decimal" },
        { key: "33", value: "Long Integer" },
        { key: "34", value: "Double Precision" },
        { key: "8", value: "Percent" },
      ],
      spatialData: [
        { key: "CURRENT_USER_LOCATION", value: "CURRENT_USER_LOCATION" },
      ],
      aggregateFunctions: [],
      currentFieldIsSystemFormula: false,
      // systemFormulaNamesden "SISTEM_NUMARASI" kaldırıldı.
      systemFormulaNames: [
        "AUTOID",
        "ISACTIVE",
        "CREATEDBY",
        "UPDATEDBY",
        "CREATEDAT",
        "UPDATEDAT",
      ],
      summary: {
        key: "1",
        value: this.$t(
          "Fields.Sum",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      avarage: {
        key: "2",
        value: this.$t(
          "Fields.Avarage",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      count: {
        key: "3",
        value: this.$t(
          "Fields.Count",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      max: {
        key: "4",
        value: this.$t(
          "Fields.Max",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      min: {
        key: "5",
        value: this.$t(
          "Fields.Min",
          {},
          { locale: this.$store.state.activeLang }
        ),
      },
      query: "",
      nullObject: [{ key: null, value: null }],

      selectedFieldTypeNumber: false,
      selectedFieldTypeText: false,
      selectedFieldTypeTextArea: false,
      selectedFieldTypeLookup: false,
      selectedFieldTypeCheckbox: false,
      selectedFieldTypeFile: false,
      selectedFieldTypeImage: false,
      selectedFieldTypeHtml: false,
      selectedFieldTypeSelectlist: false,
      selectedFieldTypeOrgUnit: false,
      selectedFieldTypePredefined: false,
      selectedFieldTypeEncrypted: false,
      selectedFieldTypeSpatial: false,
      selectedFieldTypeAutoId: false,
      selectedFieldTypeReadOnly: false,

      selectedFieldTypeRollup: false,
      selectedFieldTypeRollupDateOrDateTime: false,

      selectedFieldTypeFormula: false,
      selectedFieldTypeFormulaTextAreaOrSpatial: false,
      selectedFieldTypeFormulaNumber: false,
      selectedFieldTypeFormulaOrgUnit: false,

      isRequiredDisplay: true,
      isClientCalculateDisplay: true,
      fieldTypes: {},
      selectedField: {},

      multipleSelectValueReset: false,
      defaultValueReset: false,
      aggregateFunctionValueReset: false,
      rollupFieldValueReset: false,

      selectlistItems: [],
      selectedDefaultSpatial: [],
      selectedOrganizationUnitShowColumn: [],
      selectedPredefinedList: [],
      selectedPredefined: [],
      selectedCalculateTriggerFields: [],
      selectedLookup: [],
      selectedControllingField: [],
      selectedControllingRelationField: [],
      selectedLookupObjectFields: [],
      selectedViewFilter: [],
      selectedLookupFields: [],
      orgUnitDefaultValue: [],
      selectListDefaultValue: [],
      selectedRollup: [],
      selectedRollupType: [],
      selectedAggFunction: [],
      selectedLookupObject: [],
      selectedRollupField: [],
      activeFieldItems: [],
      selectedSelectListItems: [],
      selectedDisplayOptions: [],
      selectedFormulaFieldType: [],
      selectedNumberFieldType: [],
      selectedAutoIncrement: [],

      formulaField: {},
      customObjectFormulaName: "",
    };
  },
  methods: {
    openFormulaEditor() {
      this.formulaField = this.fieldData;
      this.formulaField.formula = this.fieldData.defaultValue;
      $(".btn-open-formula-editor-modal").trigger("click");
    },
    updateFormula(formula) {
      this.fieldData.defaultValue = formula;
    },
    onChangeCalculatedOnlyOnInsert(e) {
      if (!this.fieldData.isCalculatedOnlyOnInsert) {
        $("#message-modal").click();
      }
    },
    calculatedOnlyOnInsertNo() {
      this.fieldData.isCalculatedOnlyOnInsert = "";
      this.fieldData.isCalculatedOnlyOnInsert = true;
    },
    calculatedOnlyOnInsertYes() {
      this.fieldData.isCalculatedOnlyOnInsert = "";
      this.fieldData.isCalculatedOnlyOnInsert = false;
    },
    onChangeControlling(selected) {
      this.fieldData.controllingPublicId = selected.key;
      this.fieldData.controllingName = selected.value;
      //this.fieldData.controllingCustomObjectFormulaName = selected.key;
    },
    onChangePredefinedMultipleSelectForDefaultValue(values) {
      this.fieldData.defaultValue = values;
    },
    onChangePredefinedForDefaultValue(selected) {
      this.fieldData.defaultValue = selected.value;
    },
    onChangeIsParentPredefined() {
      this.fieldData.controllingPublicId = null;
      this.fieldData.controllingCustomObjectFormulaName = null;
      this.fieldData.controllingCustomObjectFieldFormulaName = null;
      this.selectedControllingRelationField = [];
      this.selectedControllingField = [];
    },
    onChangePrefinedControlling(selected) {
      this.fieldData.controllingPublicId = selected.key;
      // this.fieldData.controllingCustomObjectFormulaName = selected.key;
    },
    onChangeAutoIdIncrementField(selected) {
      // this.fieldData.autoIdIncrementFieldPublicId = selected.key;
      this.fieldData.autoIdIncrementFieldFormulaName = selected.key;
      this.fieldData.autoIdIncrementFieldName = selected.value;
    },
    onChangeForImageSelection(selected) {
      this.fieldData.imageSelection = selected.key;
      this.fieldData.property = selected.key;
    },
    onChangeForControllingRelationFieldPublicIdRollup(selected) {
      this.fieldData.controllingObjectFieldFormulaName = selected.key;
    },
    onChangeForControllingFieldPublicId(selected) {
      this.fieldData.controllingPublicId = selected.publicId;
      this.fieldData.controllingCustomObjectFormulaName = selected.key;
      this.fieldData.controllingName = selected.value;
      if (!String.isNullOrWhiteSpace(selected.key)) {
        this.$prodGatewayAxios
          .get(String.format("/Lcdp-FieldGet?id={0}", selected.key))
          .then((response) => {
            this.fieldData.controllingFieldLookupObjectPublicId =
              response.data.lookupObjectPublicId;
          })
          .catch(function (error) {
            //alert(error);
          });
      } else {
        this.fieldData.controllingFieldLookupObjectFormulaName = null;
      }
    },
    onChangeForControllingRelationFieldPublicId(selected) {
      this.fieldData.controllingObjectFieldFormulaName = selected.key;
    },
    onChangeMultipleSelectForCalculateTriggerField(ids) {
      // this.fieldData.calculateTriggerFieldPublicIds = ids;
      this.fieldData.calculateTriggerFieldFormulaNames = ids;
    },
    onChangeCalculateOnClientChange() {
      if (!this.fieldData.isCalculateOnClientChange) {
        this.fieldData.calculateTriggerFieldFormulaNames = null;
        this.selectedCalculateTriggerFields = [];
      }
    },
    onChangePredefinedList(selected) {
      this.fieldData.predefinedPublicId = selected.key;
      this.defaultValueReset = !this.defaultValueReset;
    },
    onChangeLookupObject(selected) {
      this.fieldData.lookupObjectPublicId = selected.publicId;
      this.fieldData.lookupObjectFormulaName = selected.key;
      this.multipleSelectValueReset = !this.multipleSelectValueReset;
      this.rollupFieldValueReset = !this.rollupFieldValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeLookupObjectViewFilter(this.nullObject);
      }
    },
    onChangeEncryptedTypeData(selected) {
      if (String.isNullOrWhiteSpace(selected.key) || selected.key === "1") {
        this.fieldData.regex = "####-##**-****-####";
        this.fieldData.readMask = "*";
      } else if (selected.key === "2") {
        this.fieldData.regex = "*";
        this.fieldData.readMask = "*";
      } else {
        this.fieldData.regex = "?";
        this.fieldData.readMask = "*";
      }
    },
    onChangeSpatial(selected) {
      this.fieldData.defaultValue = selected.key;
    },
    onChangeLookupObjectViewFilter(selected) {
      this.fieldData.viewFilterPublicId = selected.publicId;
      this.fieldData.viewFilterFormulaName = selected.key;
      this.rollupFieldValueReset = !this.rollupFieldValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeRollupField(this.nullObject);
      }
    },
    onChangeMultipleSelectForLookupObjectFields(ids) {
      this.fieldData.lookupFieldPublicIds = ids;
      //this.fieldData.lookupFieldFormulaNames = ids;
    },
    onChangeOrganizationalUnitShowColumns(ids) {
      this.fieldData.organizationalUnitShowColumns = ids;
    },
    onChangeRollupFieldType(selected) {
      this.fieldData.fieldTypeId = selected.key;
      this.aggregateFunctionValueReset = !this.aggregateFunctionValueReset;

      this.aggregateFunctions = [];
      if (selected.key == "23") {
        this.aggregateFunctions.push(this.summary);
        this.aggregateFunctions.push(this.avarage);
        this.aggregateFunctions.push(this.count);
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);

        this.fieldData.decimalPlaces = 0;
        this.fieldData.length = 12;
        this.selectedFieldTypeRollupDateOrDateTime = false;
      } else {
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);

        this.selectedFieldTypeRollupDateOrDateTime = true;
        this.fieldData.decimalPlaces = 0;
        this.fieldData.length = 0;
      }
    },
    onChangeRollupField(selected) {
      // this.fieldData.rollupFieldPublicId = selected.key;
      this.fieldData.rollupFieldFormulaName = selected.key;
    },
    onChangeAggregateFunctions(selected) {
      this.fieldData.aggregateFunctionId = selected.key;
    },
    onChangeSelectListItems: function (selected) {
      this.fieldData.defaultValuePublicIds = selected.key;
      this.fieldData.selectListSelectedItems = selected.value;
    },
    onChangeMultipleSelectForSelectlistItems: function (ids) {
      this.fieldData.defaultValuePublicIds = ids;
    },
    onChangeMultipleSelectForSelectlistItemValues: function (names) {
      this.fieldData.selectListSelectedItems = names;
    },
    onChangeFormulaFieldType(selected) {
      this.isRequiredDisplay = false;

      if (selected == "30" || selected == "31") {
        this.selectedFieldTypeFormulaTextAreaOrSpatial = true;

        this.selectedFieldTypeFormulaOrgUnit = false;
        this.selectedFieldTypeFormulaNumber = false;
      } else if (selected == "24") {
        this.selectedFieldTypeFormulaNumber = true;

        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaOrgUnit = false;
        this.aggregateFunctions.push(this.summary);
        this.aggregateFunctions.push(this.avarage);
        this.aggregateFunctions.push(this.count);
        this.aggregateFunctions.push(this.min);
        this.aggregateFunctions.push(this.max);
      } else if (selected == "32") {
        this.selectedFieldTypeFormulaOrgUnit = true;

        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaNumber = false;
      } else {
        this.selectedFieldTypeFormulaTextAreaOrSpatial = false;
        this.selectedFieldTypeFormulaOrgUnit = false;
        this.selectedFieldTypeFormulaNumber = false;
      }
    },
    onChangeNumberFieldType(selected) {
      this.fieldData.fieldTypeId = selected.key;
      this.fieldData.decimalPlaces = 0;
      this.fieldData.length = 0;
    },
    onChangetableRollup() {
      this.fieldData.tablePublicId = null;
      this.fieldData.aggregateFunction = null;
      this.fieldData.rollupFieldPublicId = null;
      this.fieldData.rollupFieldFormulaName = null;
    },
    onChangeTablePublicId(selected) {
      this.fieldData.tablePublicId = selected.key;
    },
    onChangeRollupFieldPublicId(selected) {
      // this.fieldData.rollupFieldPublicId = selected.key;
      this.fieldData.rollupFieldFormulaName = selected.key;
    },
    onChangeIsAutoIdTextBasedIncrement() {
      this.selectedAutoIncrement = [];
      this.fieldData.autoIdIncrementFieldFormulaName = null;
      this.fieldData.autoIdIncrementFieldName = null;
      this.fieldData.autoIdIncrementFieldPublicId = null;
    },
    selectlistItemsOnKeyup(event) {
      if (
        event.which === 188 ||
        event.which === 110 ||
        event.keyCode === 188 ||
        event.keyCode === 110
      ) {
        //block key , | todo:buraya tekrar bakılıcak
        this.fieldData.selectListItems = this.fieldData.selectListItems.slice(
          0,
          -1
        );
        return false;
      }

      var values = this.fieldData.selectListItems.split("\n");
      this.$refs.selectListItems.setAttribute("rows", values.length + 1);

      this.selectlistItems = [];
      values
        .filter((f) => {
          return !String.isNullOrWhiteSpace(f);
        })
        .forEach((v) => {
          this.selectlistItems.push({ key: v, value: v });
        });
    },
    displayElements(fieldType) {
      switch (fieldType) {
        case "Text":
          this.selectedFieldTypeText = true;
          break;
        case "Number":
        case "LongInteger":
        case "DoublePrecision":
          this.selectedFieldTypeNumber = true;
          break;
        case "ReadOnly":
          this.selectedFieldTypeReadOnly = true;
          this.isRequiredDisplay = false;
          this.isClientCalculateDisplay = false;
          break;
        case "Textarea":
          this.selectedFieldTypeTextArea = true;
          break;
        case "SelectList":
          this.selectedFieldTypeSelectlist = true;
          break;
        case "OrganizationalUnit":
          this.selectedFieldTypeOrgUnit = true;
          break;
        case "Predefined":
          this.selectedFieldTypePredefined = true;
          break;
        case "Lookup":
          this.selectedFieldTypeLookup = true;
          break;
        case "Checkbox":
          this.isRequiredDisplay = false;
          this.selectedFieldTypeCheckbox = true;
          break;
        case "Email":
        case "Tel":
        case "Url":
        case "Datetime":
        case "Date":
        case "Time":
          this.isClientCalculateDisplay = false;
          break;
        case "File":
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeFile = true;
          break;
        case "Image":
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeImage = true;
          break;
        case "Html":
          this.isClientCalculateDisplay = false;
          this.selectedFieldTypeHtml = true;
          break;
        case "Encrypted":
          this.selectedFieldTypeEncrypted = true;
          break;
        case "Spatial":
          this.selectedFieldTypeSpatial = true;
          break;
        case "AutoId":
          this.selectedFieldTypeAutoId = true;
          this.isRequiredDisplay = false;
          break;
        case "Rollup":
          this.selectedFieldTypeRollup = true;
          this.isRequiredDisplay = false;
          this.isCalculateOnClientChange = false;
          this.aggregateFunctions.push(this.summary);
          this.aggregateFunctions.push(this.avarage);
          this.aggregateFunctions.push(this.count);
          this.aggregateFunctions.push(this.min);
          this.aggregateFunctions.push(this.max);
          break;
        case "RollupDateTime":
          this.selectedFieldTypeRollup = true;
          this.selectedFieldTypeRollupDateOrDateTime = true;
          this.isRequiredDisplay = false;
          this.isCalculateOnClientChange = false;
          this.aggregateFunctions.push(this.min);
          this.aggregateFunctions.push(this.max);
          break;
        case "RollupDate":
          this.selectedFieldTypeRollup = true;
          this.selectedFieldTypeRollupDateOrDateTime = true;
          this.isRequiredDisplay = false;
          this.isCalculateOnClientChange = false;
          this.aggregateFunctions.push(this.min);
          this.aggregateFunctions.push(this.max);
          break;
        case "FormulaString":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaNumber":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaDate":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaDateTime":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaTextArea":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaSpatial":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        case "FormulaOrganizationalUnit":
          this.selectedFieldTypeFormula = true;
          this.isRequiredDisplay = false;
          break;
        default:
          break;
      }
    },
    filterFieldType() {
      var value = this.query.toLowerCase();
      $(".widget-fields").filter(function () {
        var parentDiv = $(this).parent("div");
        parentDiv.toggle(
          $(this)
            .find(".widget-fields-text")
            .text()
            .trim()
            .toLowerCase()
            .indexOf(value) > -1
        );
      });
    },
    onSubmit(buttonName) {
      var form = $(".form-field-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      if (this.selectedFieldTypeSelectlist) {
        if (!String.isNullOrWhiteSpace(this.fieldData.selectListItems)) {
          this.fieldData.selectListItems =
            this.fieldData.selectListItems +
            "\n" +
            this.fieldData.activeFieldItems.map((m) => m.value).join("\n");
        } else {
          this.fieldData.selectListItems = this.fieldData.activeFieldItems
            .map((m) => m.value)
            .join("\n");
        }
        this.fieldData.selectListPassiveItems = this.fieldData.passiveFieldItems
          .map((m) => m.value)
          .join("\n");
      }

      var model = { ...this.fieldData };
      model.isEditable = !this.fieldData.isEditable;

      var organizationalUnitTab = null;
      if (this.selectedFieldTypeFormulaOrgUnit) {
        organizationalUnitTab = this.$refs.formulaOrganizationalUnitTab;
      } else if (this.selectedFieldTypeOrgUnit) {
        organizationalUnitTab = this.$refs.organizationalUnitTab;
      }

      if (organizationalUnitTab) {
        model = {
          ...model,
          ...organizationalUnitTab.tabData,
        };
      }

      model.isMultipleValue = this.fieldData.isMultipleValue;

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-FieldEdit", model)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/Fields/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    addSelectlistItem(key, value, type) {
      setTimeout(() => {
        if (type == 1) {
          this.fieldData.passiveFieldItems.push({ key: key, value: value });
          this.fieldData.activeFieldItems =
            this.fieldData.activeFieldItems.filter((f) => {
              return f.key !== key;
            });
        } else {
          this.fieldData.activeFieldItems.push({ key: key, value: value });
          this.fieldData.passiveFieldItems =
            this.fieldData.passiveFieldItems.filter((f) => {
              return f.key !== key;
            });
        }
      }, 500);
    },
    formElementDisabled(isActive) {
      if (isActive) {
        $("input:not(#Name,#IsActive), textarea, select, checkbox, radio").prop(
          "disabled",
          true
        );
      } else {
        $("input:not(#Name), textarea, select, checkbox, radio").prop(
          "disabled",
          true
        );
      }
    },
    getFieldDetail() {
      this.getFieldRequestFinished = false;
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldGet?id={0}", this.$route.params.fieldId))
        .then((response) => {
          var model = response.data;
          model.isEditable = !model.isEditable;

          this.fieldData = model;
          this.getFieldRequestFinished = true;
          this.currentFieldIsSystemFormula = this.systemFormulaNames.includes(
            response.data.formulaName
          );
          if (this.currentFieldIsSystemFormula) {
            var isActive = response.data.formulaName == "ISACTIVE";
            this.formElementDisabled(isActive);
          }
          this.selectedField = this.fieldTypes.find(
            (f) => f.name == response.data.fieldType
          );
          this.displayElements(response.data.fieldType);

          if (response.data.isCalculateOnClientChange) {
            if (
              !String.isNullOrWhiteSpace(
                response.data.calculateTriggerFieldFormulaNames
              ) &&
              !String.isNullOrWhiteSpace(
                response.data.calculateTriggerFieldNames
              )
            ) {
              if (
                response.data.calculateTriggerFieldFormulaNames.includes("|") &&
                response.data.calculateTriggerFieldNames.includes("|")
              ) {
                var selectedCalculatedTriggerFieldList =
                  response.data.calculateTriggerFieldFormulaNames.split(
                    this.$systemSeparator
                  );
                var selectedCalculatedTriggerFieldNameList =
                  response.data.calculateTriggerFieldNames.split(
                    this.$systemSeparator
                  );

                selectedCalculatedTriggerFieldList.forEach((v, i) => {
                  this.selectedCalculateTriggerFields.push({
                    key: v,
                    value: selectedCalculatedTriggerFieldNameList[i],
                  });
                });
              } else {
                this.selectedCalculateTriggerFields.push({
                  key: response.data.calculateTriggerFieldFormulaNames,
                  value: response.data.calculateTriggerFieldNames,
                });
              }
            }
          }

          //#region DefaultValue
          if (!String.isNullOrWhiteSpace(response.data.defaultValue)) {
            if (response.data.fieldType == "Spatial") {
              this.selectedDefaultSpatial.push({
                key: response.data.defaultValue.toString(),
                value: response.data.defaultValue.toString(),
              });
            } else if (response.data.fieldType == "Predefined") {
              this.selectedPredefined.push({
                key: response.data.defaultValuePublicIds,
                value: response.data.defaultValue,
              });
            } else if (response.data.fieldType === "SelectList") {
              var defaulValueSelectList = response.data.defaultValue.split(
                this.$systemSeparator
              );
              var defaulValuePublicIdSelectList =
                response.data.defaultValuePublicIds.split(
                  this.$systemSeparator
                );
              if (!response.data.setSelectedItemByFormula) {
                defaulValuePublicIdSelectList.forEach((v, i) => {
                  this.selectListDefaultValue.push({
                    key: v,
                    value: defaulValueSelectList[i],
                  });
                });
              }
            }
          }
          //#endregion

          //#region predefined
          if (!String.isNullOrWhiteSpace(response.data.predefinedPublicId)) {
            this.selectedPredefinedList = this.predefinedTypeData.filter(
              (f) => f.key == response.data.predefinedPublicId
            );
          }
          //#endregion
          //#region lookup

          if (
            !String.isNullOrWhiteSpace(
              response.data.lookupObjectFormulaName &&
                !String.isNullOrWhiteSpace(response.data.lookupObjectName)
            )
          ) {
            this.selectedLookup.push({
              publicId: response.data.lookupObjectPublicId,
              key: response.data.lookupObjectFormulaName,
              value: response.data.lookupObjectName,
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              response.data.viewFilterFormulaName &&
                !String.isNullOrWhiteSpace(response.data.viewFilterName)
            )
          ) {
            this.selectedViewFilter.push({
              publicId: response.data.viewFilterPublicId,
              key: response.data.viewFilterFormulaName,
              value: response.data.viewFilterName,
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              response.data.lookupFieldFormulaNames &&
                !String.isNullOrWhiteSpace(response.data.lookupFieldNames)
            )
          ) {
            var selectedLookupFieldList =
              response.data.lookupFieldFormulaNames.split(
                this.$systemSeparator
              );
            var selectedLookupFieldNameList =
              response.data.lookupFieldNames.split(this.$systemSeparator);

            selectedLookupFieldList.forEach((v, i) => {
              this.selectedLookupFields.push({
                key: v,
                value: selectedLookupFieldNameList[i],
              });
            });
          }
          //#endregion
          //#region Rollup
          if (this.selectedFieldTypeRollup) {
            if (
              !String.isNullOrWhiteSpace(response.data.fieldTypeId) &&
              !String.isNullOrWhiteSpace(response.data.fieldType)
            ) {
              this.selectedRollup.push({
                key: response.data.fieldTypeId.toString(),
                value: response.data.fieldType,
              });
            }
            if (
              !String.isNullOrWhiteSpace(response.data.aggregateFunction) &&
              !String.isNullOrWhiteSpace(response.data.aggregateFunctionId)
            ) {
              this.selectedAggFunction.push({
                key: response.data.aggregateFunctionId.toString(),
                value: response.data.aggregateFunction,
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                response.data.lookupObjectFormulaName
              ) &&
              !String.isNullOrWhiteSpace(response.data.lookupObjectName)
            ) {
              this.selectedLookupObject.push({
                publicId: response.data.lookupObjectPublicId,
                key: response.data.lookupObjectFormulaName.toString(),
                value: response.data.lookupObjectName,
              });
            }

            if (
              !String.isNullOrWhiteSpace(response.data.viewFilterFormulaName) &&
              !String.isNullOrWhiteSpace(response.data.viewFilterName)
            ) {
              this.selectedViewFilter.push({
                publicId: response.data.viewFilterPublicId,
                key: response.data.viewFilterFormulaName,
                value: response.data.viewFilterName,
              });
            }
            if (
              !String.isNullOrWhiteSpace(response.data.rollupFieldName) &&
              !String.isNullOrWhiteSpace(response.data.rollupFieldFormulaName)
            ) {
              this.selectedRollupField.push({
                publicId: response.data.rollupFieldPublicId,
                key: response.data.rollupFieldFormulaName,
                value: response.data.rollupFieldName,
              });
            }
          }
          //#endregion
          //#region Selectlist
          //#region Image
          if (!String.isNullOrWhiteSpace(response.data.property)) {
            this.selectedDisplayOptions = this.imageSelectionData.filter(
              (f) => f.key == response.data.property
            );
          }
          if (!String.isNullOrWhiteSpace(response.data.activeFieldItems)) {
            this.activeFieldItems = response.data.activeFieldItems;
            this.activeFieldItems
              .filter((f) => {
                return !String.isNullOrWhiteSpace(f);
              })
              .forEach((v) => {
                this.selectlistItems.push({ key: v.key, value: v.value });
              });
          }

          if (
            !String.isNullOrWhiteSpace(response.data.defaultValue) &&
            !String.isNullOrWhiteSpace(response.data.defaultValuePublicIds)
          ) {
            if (
              response.data.defaultValue.includes("|") &&
              response.data.defaultValuePublicIds.includes("|")
            ) {
              var selectedValuePublicIds =
                response.data.defaultValuePublicIds.split(
                  this.$systemSeparator
                );
              var selectedValue = response.data.defaultValue.split(
                this.$systemSeparator
              );
              selectedValuePublicIds.forEach((v, i) => {
                this.selectedSelectListItems.push({
                  key: v,
                  value: selectedValue[i],
                });
              });
            } else {
              this.selectedSelectListItems.push({
                key: response.data.defaultValuePublicIds,
                value: response.data.defaultValue,
              });
            }
          }
          //#endregion
          //#region Formula
          if (response.data.fieldType.includes("Formula")) {
            this.onChangeFormulaFieldType(response.data.fieldTypeId);
            this.selectedFormulaFieldType.push({
              key: response.data.fieldTypeId,
              value: response.data.fieldType,
            });
          }
          //#endregion

          if (
            response.data.fieldType == "Number" ||
            response.data.fieldType == "LongInteger" ||
            response.data.fieldType == "DoublePrecision" ||
            response.data.fieldType == "Percent"
          ) {
            this.selectedNumberFieldType.push({
              key: response.data.fieldTypeId.toString(),
              value:
                response.data.fieldType === "Number"
                  ? this.numberFieldTypes[0].value
                  : response.data.fieldType,
            });
          }

          //#region AutoId
          if (
            !String.isNullOrWhiteSpace(
              response.data.autoIdIncrementFieldFormulaName
            )
          ) {
            this.selectedAutoIncrement.push({
              publicId: response.data.autoIdIncrementFieldPublicId,
              key: response.data.autoIdIncrementFieldFormulaName,
              value: response.data.autoIdIncrementFieldName,
            });
          }
          //#endregion
          this.fieldData.isParentPredefined =
            !String.isNullOrWhiteSpace(this.fieldData.controllingName) &&
            (!String.isNullOrWhiteSpace(
              this.fieldData.predefinedDependencyPublicId
            ) ||
              !String.isNullOrWhiteSpace(
                this.fieldData.controllingCustomObjectFormulaName
              ));
          if (this.fieldData.isParentPredefined) {
            if (
              !String.isNullOrWhiteSpace(
                this.fieldData.predefinedDependencyPublicId
              )
            ) {
              this.selectedControllingField.push({
                key: response.data.predefinedDependencyPublicId,
                value: response.data.controllingName,
              });
            } else if (
              !String.isNullOrWhiteSpace(
                this.fieldData.controllingCustomObjectFormulaName
              )
            ) {
              this.selectedControllingField.push({
                publicId: response.data.controllingPublicId,
                key: response.data.controllingCustomObjectFormulaName,
                value: response.data.controllingName,
              });
            }
            this.fieldData.controllingFieldLookupObjectFormulaName = null;
            this.$prodGatewayAxios
              .get(
                String.format(
                  "/Lcdp-FieldGet?id={0}",
                  response.data.controllingPublicId
                )
              )
              .then((response) => {
                this.fieldData.controllingFieldLookupObjectFormulaName =
                  response.data.lookupObjectFormulaName;
              })
              .catch(function (error) {
                //alert(error);
              });
          }

          if (
            !String.isNullOrWhiteSpace(
              this.fieldData.controllingObjectFieldName
            ) &&
            !String.isNullOrWhiteSpace(
              this.fieldData.controllingObjectFieldFormulaName
            )
          ) {
            this.selectedControllingRelationField.push({
              publicId: response.data.controllingObjectFieldPublicId,
              key: response.data.controllingObjectFieldFormulaName,
              value: response.data.controllingObjectFieldName,
            });
          }

          if (
            !String.isNullOrWhiteSpace(this.fieldData.lookupFieldPublicIds) &&
            !String.isNullOrWhiteSpace(this.fieldData.lookupFieldNames)
          ) {
            var publicIds = response.data.lookupFieldPublicIds.split(
              this.$systemSeparator
            );
            var values = response.data.lookupFieldNames.split(
              this.$systemSeparator
            );
            publicIds.forEach((v, i) => {
              this.selectedLookupObjectFields.push({
                key: v,
                value: values[i],
              });
            });
          }

          this.fieldData.customObjectFormulaName =
            this.$store.getters._customObjectDto.formulaName;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getFieldTypes() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldListFieldTypes"))
        .then((response) => {
          this.fieldTypes = response.data;
          this.getFieldDetail();
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getFieldTypes();
    // this.fieldData.customObjectFormulaName =
    //   this.$store.state.customObjectDto.formulaName;
  },
};
</script>
